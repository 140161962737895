<template>
  <div class="status_wrapper">
    <v-row v-if="isPageCat === 'holiday'">
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('daysapproved')"
          class="text-center"
        >
          <b-card-text
            >{{ statistics_data.daysapproved }}
            <span
              v-if="statistics_data.daysapproved_notarrived > 0"
              style="color: green;"
              >( + {{ statistics_data.daysapproved_notarrived }})</span
            ></b-card-text
          >
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('holidayspending')"
          class="text-center"
        >
          <b-card-text
            >{{ statistics_data.current_holidaypending }}
            <span
              v-if="isteam === 'my' && statistics_data.last_holidaypending > 0"
              style="color: red;"
              >({{ statistics_data.last_holidaypending }})</span
            >
          </b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Incidence')"
          class="text-center"
        >
          <b-card-text>{{ statistics_data.incidence_count }} </b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('dayspendingapproval')"
          class="text-center"
        >
          <b-card-text>{{ statistics_data.dayspendingapproval }}</b-card-text>
        </b-card>
      </v-col>
    </v-row>
    <v-row v-if="isPageCat === 'leaves'">
      <v-col cols="12" sm="4" md="4">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Days of Leaves')"
          class="text-center"
        >
          <b-card-text
            >{{ statistics_data.daysapproved }}
            <span
              v-if="statistics_data.daysapproved_notarrived > 0"
              style="color: green;"
              >( + {{ statistics_data.daysapproved_notarrived }})</span
            ></b-card-text
          >
        </b-card>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Incidence')"
          class="text-center"
        >
          <b-card-text>{{ statistics_data.incidence_count }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('pendingapproval')"
          class="text-center"
        >
          <b-card-text>{{ statistics_data.pendingapproval_lines }}</b-card-text>
        </b-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Statistics",
  props: ["isteam", "data", "isPageCat"],
  data() {
    return {
      statistics_data: {
        daysapproved: 0,
        current_holidaypending: 0,
        last_holidaypending: 0,
        dayspendingapproval: 0,
      },
    };
  },
  watch: {
    async data(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal) {
        this.statistics_data = newVal;
      }
      return newVal;
    },
  },
  computed: {},
  methods: {
    setdata(data) {
      this.statistics_data = data;
    },
    init() {
      this.statistics_data = this.data;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
